<template>
    <div class="text-center">
        <h3 class="mt-20 fs23">{{ $t("Cancellation of freepaid recharge") }}</h3>
        <h1 class="mt-15">{{ $t("Cancel failed") }}</h1>
        <h3 class="mt-15 fs23">הביטול נכשל מפני שהיה שימוש בכרטיס</h3>
        <h3 class="mt-10 fs23">הודעת שגיאה: דלךגכמדךגל</h3>
        <button class="primary-btn result-btn">
            {{ $t("Closed") }}
        </button>
    </div>
</template>

<script>
export default {
    name: "CancellationFaild",
    mounted() {
        document.getElementsByTagName("html")[0].style.background = "#FFA5A5";
    },
}
</script>

<style lang="scss" scoped>
.result-btn {
    margin: 0 auto;
    margin-top: 40px;
    max-width: 400px;
}
</style>